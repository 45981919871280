// Styles
import "../sass/main.scss";

// Images.
import "../img/brand-logo.svg";

import "magnific-popup";
import BeerSlider from "beerslider";
import barba from "@barba/core";
import Swiper from "swiper/bundle";
import gsap from "gsap";
import LocomotiveScroll from "locomotive-scroll";
import lozad from "lozad";

(function ($) {
  "use strict";

  $.fn.BeerSlider = function (options) {
    options = options || {};
    return this.each(function () {
      new BeerSlider(this, options);
    });
  };

  function debug(report, arg = null) {
    if (arg != null) {
      // console.log(report, arg);
    } else {
      // console.log(report);
    }
  }

  var scroll;
  var isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };
  const loadingScreen = document.querySelector(".loading-screen");
  const headerLogo = document.querySelector(".logo-link");
  const mainNavigation = document.querySelector("#page-header");
  const mobileOpener = document.querySelector(".mobile-toggler");
  const toggleBtn = document.querySelector(".mobile-toggler-btn");
  const mobileNavigation = document.querySelector(".mobile-menu");
  var showMenu = false;
  let vpwidth;
  let mainNavvh = "";
  let logoHeadervw = "";

  barba.hooks.before(() => {
    document.querySelector("html").classList.add("is-transitioning");
  });
  barba.hooks.after(() => {
    document.querySelector("html").classList.remove("is-transitioning");
  });

  barba.init({
    sync: true,
    // prevent: ({ el }) => el.classList && el.classList.contains('prevent'),
    transitions: [
      {
        name: "custom-transition",
        async once({ next }) {
          // handleCustomCursor();
          setRootVars();
          barbaPreventContact(next);
          await contentAnimation(next.container);
          set_current_menu_item(next);
          mobileToggle();
        },
        async leave(data) {
          await pageTransitionIn();
          data.current.container.remove();

          // On ferme le menu si il est ouvert
          if (!showMenu) {
            // debug(showMenu, toggleBtn);
            toggleBtn.classList.remove("open");
            mobileNavigation.classList.remove("menu-h");
            showMenu = false;
          }
        },
        async beforeEnter({ next }) {
          scroll.destroy();
        },
        async enter({ next }) {
          barbaPreventContact(next);
          await contentAnimation(next.container);
          set_current_menu_item(next);
          mobileToggle();
          scroll.update();
        },
      },
    ],
  });

  /**
   * Lozad
   */
  const observer = lozad('.lozad', {
    loaded: function (el) {
      el.classList.add('loaded');
      //scroll.update();
      debug(el, "loaded");
    }
  });

  /**
   * Utils
   */

  window.addEventListener("resize", get_WiewportWidth, false);
  function get_WiewportWidth() {
    // debug("WPW");
    var vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    return vw;
  }

  window.addEventListener("resize", setRootVars, false);
  function setRootVars() {
    const root = document.documentElement;
    const herovh =
      Math.round(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      ) / 100;
    mainNavvh = Math.round(mainNavigation.offsetHeight || 0);
    logoHeadervw = Math.round(headerLogo.offsetWidth || 0);

    root.style.setProperty("--herovh", herovh + "px");
    root.style.setProperty("--mainNavvh", mainNavvh + "px");
    root.style.setProperty("--logovw", logoHeadervw + "px");
  }

  function preventBarbaWPAdminBar() {
    const wpadminbar = document.getElementById("wpadminbar");
    if (typeof wpadminbar != "undefined" && wpadminbar != null) {
      const links = wpadminbar.querySelectorAll("a");
      links.forEach(function (link) {
        link.setAttribute("data-barba-prevent", "self");
      });
    }
  }
  setTimeout(function () {
    preventBarbaWPAdminBar();
  }, 300);

  /**
   * Fonctions pablooo
   */

  function ScrolltoContent(container) {
    let scrollDown = container.querySelector("#home-scroll-down");
    let target = container.querySelector("#content");
    debug("ScrolltoContent", "Init");
    if (typeof scrollDown != "undefined" && scrollDown != null) {
      debug("ScrolltoContent", "Présent");
      scrollDown.addEventListener("click", (event) => {
        debug("ScrolltoContent", "FIRE!");
        scroll.scrollTo(target);
      });
    }
  }

  function Init_Swiper() {
    var hero = new Swiper(".swiper-hero", {
      //init: false,
      // effect: "fade",
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      on: {
        init: function () {
          debug("swiper initialized");
        },
      },
    });

    var testiswiper = new Swiper(".swiper-testi", {
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        clickable: true,
      },
    });

    var slider = new Swiper(".flex-slider", {
      slidesPerView: 2,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        640: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 6,
        },
      },
      on: {
        init: function () {
          debug("swiper initialized");
        },
      },
    });
  }
  function InitBeerSlider() {
    debug("InitBeerSlider");
    $(".beer-slider").each(function (index) {
      debug(index);
      $(this).BeerSlider({ start: 50 });
    });
  }

  function smooth(container) {
    debug("smooth");
    let sscroll = container.querySelector("[data-scroll-container]");
    let showMobileToggle = false;
    let showMenu = false;
    const mobileOpener = document.querySelector(".mobile-toggler");
    const toggleBtn = document.querySelector(".mobile-toggler-btn");
    const mobileNavigation = document.querySelector(".mobile-menu");

    scroll = new LocomotiveScroll({
      el: sscroll,
      smooth: false,
      repeat: true,
    });

    scroll.on("scroll", function (datas) {
      // debug(datas);
      if (datas.scroll.y >= mainNavvh) {
        if (!showMobileToggle) {
          showMobileToggle = true;
          mobileOpener.classList.add("active");
        }
      } else if (showMobileToggle) {
        showMobileToggle = false;
        mobileOpener.classList.remove("active");
      }
    });

    mobileOpener.addEventListener("click", (event) => {
      event.preventDefault();
      debug("mobileToggle()", showMenu);

      if (!showMenu) {
        toggleBtn.classList.add("open");
        mobileNavigation.classList.add("menu-h");
        showMenu = true;
      } else {
        toggleBtn.classList.remove("open");
        mobileNavigation.classList.remove("menu-h");
        showMenu = false;
      }
    });
  }
  window.addEventListener("load", function (event) {
    debug("Toutes les ressources sont chargées !");
    // scroll.update();
  });

  function set_current_menu_item(next) {
    var menus = document.querySelectorAll(".menu");
    // var menu = document.querySelector(".menu");
    // var nextItem = menu.querySelector(`a[href="${next.url.href}"]`);
    // debug(next);
    // debug(menus);

    menus.forEach(function (menu) {
      var nextItem = menu.querySelector(`a[href="${next.url.href}"]`);
      var activeItems = menu.querySelector(".item-active");
      if (activeItems !== null) {
        activeItems.classList.remove("item-active");
      }
      if (nextItem !== null) {
        nextItem.classList.add("item-active");
        debug(nextItem);
      }
    });
  }

  function pageTransitionIn() {
    var transition;
    // debug("desktop", get_WiewportWidth());
    transition = gsap.timeline().to(document.querySelector(".is-animated"), {
      duration: 0.25,
      opacity: 0,
      stagger: 0.4,
      ease: "power1.inOut",
    });

    // Scroll to top
    // scroll.scrollTo("top", 0, 200);
    return transition;
  }

  function pageTransitionOut(container) {
    // Scroll to top
    scroll.scrollTo("top", 0, 200);
    var transition;
    debug("desktop", get_WiewportWidth());
    transition = gsap
      .timeline({ delay: 1 })
      .add("start")
      .to(
        loadingScreen,
        {
          duration: 0.4,
          width: 306,
          opacity: 0.5,
          ease: "power1.inOut",
        },
        "start"
      )
      .call(contentAnimation, [container], "start");

    return transition;
  }

  function contentAnimation(container) {
    debug("contentAnimation");
    observer.observe();
    convertSVG();
    Init_Swiper();
    MPInit();
    InitBeerSlider();
    ScrolltoContent(container);

    return gsap
      .timeline()
      .from(container.querySelector(".is-animated"), {
        duration: 0.25,
        opacity: 0,
        stagger: 0.4,
        ease: "power1.inOut",
      })
      .call(function () {
        smooth(container);
        scroll.scrollTo("top", 0, 200);
        GoogleMap();
      });
  }

  function barbaPreventContact(next) {
    var els = document.querySelectorAll("a[href='" + contacturl + "']");
    for (var i = 0, l = els.length; i < l; i++) {
      var el = els[i];
      el.setAttribute("data-barba-prevent", "self");
    }
  }

  /**
   * Menu Mobile
   * Animation
   */

  function mobileToggle() { }

  /**
   * Magnific Popup
   */
  function MPInit() {
    $.extend(true, $.magnificPopup.defaults, {
      tClose: "Fermer (Esc)", // Alt text on close button
      tLoading: "Chargement...", // Text that is displayed during loading. Can contain %curr% and %total% keys
      gallery: {
        tPrev: "Précédent (Flèche de gauche)", // Alt text on left arrow
        tNext: "Suivant (Flèche de droite)", // Alt text on right arrow
        tCounter: "%curr% de %total%", // Markup for "1 of 7" counter
      },
      image: {
        tError: '<a href="%url%">The image</a> ne peut être chargée.', // Error message when image could not be loaded
      },
      ajax: {
        tError: '<a href="%url%">The content</a> ne peut être chargé.', // Error message when ajax request failed
      },
    });

    $(".section-gallery").magnificPopup({
      delegate: "a",
      type: "image",
      closeOnContentClick: false,
      closeBtnInside: false,
      mainClass: "mfp-with-zoom mfp-img-mobile",
      image: {
        verticalFit: true,
        titleSrc: function (item) {
          return item.el.attr("title");
        },
      },
      gallery: {
        enabled: true,
      },
      zoom: {
        enabled: true,
        duration: 300, // don't foget to change the duration also in CSS
        opener: function (element) {
          return element.find("img");
        },
      },
    });
  }

  function convertSVG() {
    jQuery("img.svg").each(function () {
      var $img = jQuery(this);
      var imgID = $img.attr("id");
      var imgClass = $img.attr("class");
      var imgURL = $img.attr("src");

      jQuery.get(imgURL, function (data) {
        var $svg = jQuery(data).find("svg");

        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }
        $svg = $svg.removeAttr("xmlns:a");
        $svg = $svg.removeAttr("width");
        $svg = $svg.removeAttr("height");

        $img.replaceWith($svg);
      });
    });
  }

  function GoogleMap() {
    debug("GoogleMap!");
    jQuery(".acf-map").each(function () {
      debug("yeahè!");
      var map = initMap(jQuery(this));
    });
  }
  // Single Map et Contact Map
  function initMap($el) {
    debug("initMap!");
    // Find marker elements within map.
    var $markers = $el.find(".marker");

    // Create gerenic map.
    var mapArgs = {
      zoom: $el.data("zoom") || 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      styles: [
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [{ color: "#ffffff" }, { lightness: 17 }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }, { lightness: 18 }],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }, { lightness: 16 }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#dedede" }, { lightness: 21 }],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            { visibility: "on" },
            { color: "#ffffff" },
            { lightness: 16 },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            { saturation: 36 },
            { color: "#333333" },
            { lightness: 40 },
          ],
        },
        { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [{ color: "#fefefe" }, { lightness: 20 }],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
        },
      ],
    };
    var map = new google.maps.Map($el[0], mapArgs);

    // Add markers.
    map.markers = [];
    $markers.each(function () {
      initMarker(jQuery(this), map);
    });

    // Center map based on markers.
    centerMap(map);

    // Return map instance.
    return map;
  }

  function initMarker($marker, map) {
    // Get position from marker.
    var lat = $marker.data("lat");
    var lng = $marker.data("lng");
    var latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    var image = {
      url: $marker.attr("data-type")
        ? $marker.attr("data-type")
        : themedir + "/assets/images/marker.svg",
      scaledSize: new google.maps.Size(40, 40),
    };

    // Create marker instance.
    var marker = new google.maps.Marker({
      position: latLng,
      map: map,
      // icon: image,
    });

    // Append to reference for later use.
    map.markers.push(marker);
    /*
    // If marker contains HTML, add it to an infoWindow.
    if( $marker.html() ){

      // Create info window.
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // Show info window when marker is clicked.
      google.maps.event.addListener(marker, 'click', function() {
        infowindow.open( map, marker );
      });
    }
    */
  }

  function centerMap(map) {
    // Create map boundaries from all map markers.
    var bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function (marker) {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng(),
      });
    });

    // Case: Single marker.
    if (map.markers.length == 1) {
      map.setCenter(bounds.getCenter());

      // Case: Multiple markers.
    } else {
      //debug("multiple marker bro ");
      map.fitBounds(bounds);
      //map.panToBounds(bounds);
      //debug(map.getZoom());
      //map.setZoom(map.getZoom()-10);
      //debug(map.getZoom());
    }

    google.maps.event.addListenerOnce(map, "bounds_changed", function (event) {
      debug("multiple marker bro ");
      debug(map.getZoom());
      this.setZoom(map.getZoom() - 1);
      if (this.getZoom() > 15) {
        this.setZoom(15);
      }
      debug(map.getZoom());
    });
  }

  if (isMobile.any()) {
    jQuery("body").removeClass("custom-cursor");
  }
})(jQuery);
